import type { EditorReadyFn, GetAppManifestFn } from '@wix/yoshi-flow-editor';

import type { EditorSDK } from './editor/constants/types';
import profileCardConfig from './components/ProfileCard/.component.json';
import { createAppAPI } from './editor/services/app-api';
import * as publicApi from './editor/services/public-api';
import {
  initExperiments,
  isADIHorizontalEnabled,
} from './editor/services/experiments';
import {
  getLayoutType,
  maybeSetHorizontalLayout,
} from './editor/services/styles';
import { initMonitoring, toMonitored } from './editor/services/monitoring';
import { getIsMembersAreaV2Context } from './editor/services/members-area-api';

let appDefId: string;

const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefinitionId,
  options,
  flowApi,
) => {
  appDefId = appDefinitionId;
  initExperiments(flowApi);
  await initMonitoring(editorSDK, options, flowApi);
  await editorSDK.editor.setAppAPI('', createAppAPI(editorSDK));

  if (!options.firstInstall) {
    return;
  }

  const allowHorizontalInADI = await isADIHorizontalEnabled();
  const isADI = options.origin.type.includes('ADI');
  const isHorizontalLayout = !isADI || allowHorizontalInADI;

  if (isHorizontalLayout) {
    await toMonitored('setHorizontalLayout', () =>
      maybeSetHorizontalLayout(editorSDK, appDefId),
    );
  }
};

const _exports = (editorSDK: EditorSDK) => ({
  public: {
    isHorizontalLayout: () => getLayoutType(editorSDK, appDefId),
    refreshApp: () => publicApi.refreshApp(editorSDK),
    setBooleanStyleParamForWidgets: (key: string, value: boolean) =>
      publicApi.setBooleanStyleParamForWidgets(editorSDK, appDefId, key, value),
    setHorizontalLayout: () =>
      publicApi.setHorizontalLayout(editorSDK, appDefId),
    setSidebarLayout: () => publicApi.setSidebarLayout(editorSDK, appDefId),
  },
});

export const getAppManifest: GetAppManifestFn = async (
  { appManifestBuilder },
  editorSDK,
  _contextParams,
  _flowAPI,
) => {
  const isMembersAreaV2Context = await getIsMembersAreaV2Context(editorSDK);

  return appManifestBuilder
    .configureWidget(profileCardConfig.id, (builder) =>
      builder.behavior().set({ removable: !isMembersAreaV2Context }),
    )
    .build();
};

export { editorReady, _exports as exports };
